import React from "react";
import RedirectLogic from '../components/Redirect';

const Redirect = () => (
    <div>
        <RedirectLogic/>
    </div>
)

export default Redirect
